import React, { useEffect, useRef, useState } from 'react';
import './ScrollableContent.css'; // Make sure this contains the necessary styles
import TestLogin from './TestLogin'; // Unused component, consider if it should be included
import Footer from './Footer';
import { useSelector, useDispatch } from "react-redux";
import Template2 from "../../../Pages/Templates/Template2/Template2";
import Template6 from "../../../Pages/Templates/Template6/Template6";
import Template8 from "../../../Pages/Templates/Template8/Template8";
import Template12 from "../../../Pages/Templates/Template12/Template12";
import Template14 from "../../../Pages/Templates/Template14/Template14";
import Template15 from "../../../Pages/Templates/Template15/Template15";
import Template16 from "../../../Pages/Templates/Template16/Template16";
import Temp12 from "../../../Pages/Templates/tem18/Temp18";
import { setTheme } from "../../../Redux/Slices/FormSlice";
import { FaPlus } from "react-icons/fa";
import SpeedDial from "@mui/material/SpeedDial";
import { PiUploadSimpleBold } from "react-icons/pi";
import tinycolor from "tinycolor2"; 
import { Box } from '@mui/material';

const ScrollableContent = ({ onScroll, scrollY }) => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form || {});
  const { theme = -1, colors = {} } = form;
  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");
 
  const [selectedTemplate, setSelectedTemplate] = useState(form.theme);
  useEffect(() => {
    if (form.theme !== -1) {
      setSelectedTemplate(form.theme );
    } else {
      setSelectedTemplate(1);
    }
  }, [form.theme]);

  const scrollableRef = useRef(null);
   const renderTemplate = () => {
    switch (selectedTemplate) {
      case 1:
        return <Template2 />;
      case 2:
        return <Template6 />;
    
      case 3:
        return <Template8 />;
     
      case 4:
        return <Template12 />;
      case 5:
        return <Template15 />;
      case 6:
        return <Template14 />;
    
      case 7:
        return <Template16 />;
   
      case 8:
        return <Temp12 />;
      default:
        return <Template2 />;
    }
  };

  const iPhoneFrame = {
    width: "100%",
    maxWidth: "300px",  // Reduced width
    height: "550px",    // Reduced height
    border: "none",
    borderRadius: "40px",
    background: "linear-gradient(145deg, #e0e0e0, #ffffff)",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
    position: "relative",
    overflow: "hidden",
    transformStyle: "preserve-3d",
  };

  const iPhoneScreen = {
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    borderRadius: "30px",
    overflowX: "hidden",
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "thin",
    scrollbarColor: "transparent transparent",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  };



  const iPhoneButton = {
    height: "8px",   // Adjusted size
    backgroundColor: "black",
    position: "absolute",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "60px",   // Adjusted size
    borderRadius: "30px",
    zIndex: "400",
  };

  const iPhoneFrameStyle = {
    ...iPhoneFrame,
    border: `4px solid #000`,
  };

  const iPhoneButtonStyle = {
    ...iPhoneButton,
    backgroundColor: "black",
  };

  const StylediPhoneFrame = ({ children }) => (
    <Box sx={iPhoneFrameStyle}>
      {children}
      <div style={iPhoneButtonStyle} />
    </Box>
  );


  const handleScroll = (event) => {
   //
  };

  return (
    
    <div
      ref={scrollableRef}
      className="scrollable-container" 
      onScroll={handleScroll} // Use the custom handleScroll function
      style={{ overflowY: "auto", height: "100%", width: "100%", transformOrigin: "top left",overflowX:"none"}}
    >
      <div style={{ marginRight:"0px",marginLeft:"0px",paddingRight:"5px",paddingLeft:"5px",paddingTop:"25px", height:"100%",
        backgroundColor:"black", paddingLeft:"4px",paddingRight:"4px",paddingBottom:"24px"

       }}
     
      >
      <Box sx={iPhoneScreen}>
        {/* Ensure any repeated elements are keyed properly if they are identical */}
        {renderTemplate()}
        </Box>
      </div>
    </div>
  ); 
};

export default ScrollableContent;