import React from 'react';
import styles from './style.module.css';
import { Box } from '@mui/material';
import ScrollableContent from '../../IphonePreView/components/ScrollableContent';

export default function Index() {
  return (
    <Box
      className={styles.nav}
      sx={{
        position: 'absolute',
        top: '50px',
    
        backgroundColor: '#fff',
     
        zIndex: 699,
        transform: 'scale(0.4)', // Downscale to 50%
        transformOrigin: 'top left', // Origin for scaling
      }}
    >

      {/* Scrollable Content */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <ScrollableContent />
      </Box>
    </Box>
  );
}