import React from 'react';
import { useSelector } from "react-redux";
import image from '../../../assets/images/person.jpg';
import Pdfi from '../../../assets/images/PDF-icon.png';
import { BsFiletypePdf } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa";
import tinycolor from "tinycolor2";
const Template16 = () => {
  const form = useSelector((state) => state.form);

  const [textColor, setTextColor] = React.useState("#ffffff");
  const [textButtonColor, setTextButtonColor] = React.useState("#ffffff");
  function hexToRgba(hex, opacity = 1) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex?.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex?.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  }
  React.useEffect(() => {
    if (form?.colors?.mainBackground) {
      const brightness = tinycolor(form.colors.mainBackground).getBrightness();
      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }

    if (form?.colors?.buttonsBackground) {
      const brightness = tinycolor(form.colors.buttonsBackground).getBrightness();
      setTextButtonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form?.colors?.mainBackground, form?.colors?.buttonsBackground]);

  const social = form?.contacts.map(contact => ({
    icon: contact.logo ? <img src={contact.logo} alt={contact.value} style={{ width: "70px", height: "70px" }} /> : null,
    value: contact.value
  }));
  const socialCuston = form?.contactsCustom.map(contact => ({
    icon: contact.logo ? <img src={contact.logo} alt={contact.value} style={{ width: "70px", height: "70px" }} /> : null,
    value: contact.value
  }));
  const pdfs = form?.sections.filter(section => section.content && section.content[0]?.type === 'pdf').map(section => ({
    url: section.media,
    name: section.sectionName
  }));

  const videos = form?.sections.filter(section => section.content && section.content[0]?.type === 'video').map(section => ({
    url: section.media
  }));

  return (
    <div className='w-[100%] h-full' style={{ background: form?.colors?.mainBackground }}>
      <div style={{ position: "relative", height: '40vh', width: "100%", overflow: "hidden" }}>
        <div style={{
          position: "absolute", top: 0, left: 0, width: "100%", height: "100%",
          backgroundImage: `url(${form?.profileImage || image})`, backgroundPosition: "center",
          backgroundRepeat: "no-repeat", backgroundSize: "cover", zIndex: 0,
        }}>
        </div>
        <div style={{
          position: "absolute", bottom: 0, left: 0, width: "100%", height: "40%",
          backgroundImage: `linear-gradient(to top, ${hexToRgba(form?.colors?.mainBackground, 1)}, ${hexToRgba(form?.colors?.mainBackground, 0.9)}, ${hexToRgba(form?.colors?.mainBackground, 0.7)}, ${hexToRgba(form?.colors?.mainBackground, 0)})`,
          zIndex: 1,
        }}>
          <div className='flex flex-col justify-end items-start h-full ml-5 -mt-5'>
            <h1 className='text-3xl' style={{ fontFamily: "Poppins-Light", color: textColor }}>
              {form?.basic?.firstName} {form?.basic?.lastName}
            </h1>
            <p className='text-center text-sm' style={{ fontFamily: "Poppins-Light", color: textColor }}>
              {form?.basic?.businessName}
            </p>
          </div>
        </div>
      </div>
      <div className='mb-4' style={{ background: form?.colors?.mainBackground }}>
        <div className='flex justify-between px-10 py-5 w-[100%]'>
          <div>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.92"
            height="28.715"
            viewBox="0 0 26.92 28.715"
          >
            <g id="whatsapp-whats-app-svgrepo-com" transform="translate(-2)">
              <path
                id="Path_105961"
                data-name="Path 105961"
                d="M15.46,0A13.442,13.442,0,0,0,2,13.46a13.012,13.012,0,0,0,2.871,8.256L2.987,27.459a1.013,1.013,0,0,0,.269.987.64.64,0,0,0,.538.269.54.54,0,0,0,.359-.09l6.192-2.782A12.454,12.454,0,0,0,15.46,26.92,13.46,13.46,0,0,0,15.46,0Z"
                fill="#25d366"
              />
              <path
                id="Path_105962"
                data-name="Path 105962"
                d="M22.024,17.528a3.3,3.3,0,0,1-2.5,1.872,2.4,2.4,0,0,1-.78.078,8.279,8.279,0,0,1-3.2-.858A12.848,12.848,0,0,1,10.326,14.1v-.078A6.61,6.61,0,0,1,9,10.587a4.22,4.22,0,0,1,1.17-2.964A2.178,2.178,0,0,1,11.73,7h.39c.546,0,.936.156,1.326.936l.312.624a9.2,9.2,0,0,0,.624,1.4,1.2,1.2,0,0,1,0,1.248,1.4,1.4,0,0,1-.39.546q-.117.234-.234.234l-.156.156a7.138,7.138,0,0,0,1.326,1.638,5.712,5.712,0,0,0,2.028,1.248h0c.156-.156.312-.468.546-.7l.078-.156a1.34,1.34,0,0,1,1.638-.468c.312.156,2.028.936,2.028.936l.156.078c.234.156.546.234.7.546A3.69,3.69,0,0,1,22.024,17.528Z"
                transform="translate(0.28 0.221)"
                fill="#fff"
              />
            </g>
          </svg>
          </div>
          <div className='border-r border-l border-gray-390 px-10'>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28.372"
            height="28.715"
            viewBox="0 0 36.372 28.715"
          >
            <path
              id="envelope-Filled_1_"
              d="M556.547,2787.3l-13.726-9.744a8.606,8.606,0,0,1,7.293-4.059h19.143a8.612,8.612,0,0,1,7.293,4.04l-13.687,9.725A5.739,5.739,0,0,1,556.547,2787.3Zm21.287-5.954-12.788,9.074a9.73,9.73,0,0,1-5.379,1.627,9.513,9.513,0,0,1-5.283-1.608l-12.845-9.094c-.019.249-.038.517-.038.766V2793.6a8.627,8.627,0,0,0,8.614,8.614h19.143a8.627,8.627,0,0,0,8.614-8.614v-11.486C577.872,2781.866,577.853,2781.6,577.834,2781.349Z"
              transform="translate(-541.5 -2773.5)"
              fill="#e95f52"
            />
          </svg>
          </div>
          <div>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.715"
            height="28.715"
            viewBox="0 0 28.715 28.715"
          >
            <g id="phone-Filled" transform="translate(0 0)">
              <path
                id="phone-Filled_1_"
                d="M760.688,2869.966a6.458,6.458,0,0,1-4.9,2.249h-.177A22.738,22.738,0,0,1,733.5,2850.1a6.469,6.469,0,0,1,2.263-5.1,6.087,6.087,0,0,1,5.061-1.407,5.237,5.237,0,0,1,.716.18,2.251,2.251,0,0,1,1.511,1.8l1.013,6.431a2.291,2.291,0,0,1-.818,2.132c-.127.1-.258.2-.4.292a.814.814,0,0,0-.326,1.007,14.9,14.9,0,0,0,7.747,7.749.819.819,0,0,0,1.014-.335c.071-.1.145-.2.221-.3a2.287,2.287,0,0,1,2.163-.827l6.547,1.089a2.226,2.226,0,0,1,1.792,1.562c.041.146.08.313.113.5A6.158,6.158,0,0,1,760.688,2869.966Z"
                transform="translate(-733.499 -2843.501)"
                fill="#0288d1"
              />
            </g>
          </svg>
          </div>
        </div>
        <div className='flex justify-start items-center gap-2 mt-4 ml-5'>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="20.5" viewBox="0 0 22.5 28.5">
            <path id="map-marker-Filled_1_" d="M221.75,2782.75a3,3,0,1,1-3-3A3.009,3.009,0,0,1,221.75,2782.75Zm8.25,0c0,9.12-7.005,14.835-10.005,16.86a2.11,2.11,0,0,1-1.245.39,2.188,2.188,0,0,1-1.26-.39c-3-2.025-9.99-7.725-9.99-16.86a11.25,11.25,0,0,1,22.5,0Zm-5.25,0a6,6,0,1,0-6,6A6,6,0,0,0,224.75,2782.75Z" transform="translate(-207.5 -2771.5)" fill="#E4405F" />
          </svg>
          <p className='text-gray-500 text-xs' style={{ fontFamily: "Poppins-Light",color: textColor }}>{form?.basic?.location}</p>
        </div>
        <div className="w-[270px] rounded-2xl p-4 mt-1">

          <h1
          className="text-xl mb-2"
          style={{ fontFamily: "Poppins-Light",color: textColor }}
        >
          Job Title
        </h1>
        <p
          className="text-xs w-[full] "
          style={{ fontFamily: "Poppins-Light",color: textColor }}
        >
          {form?.basic?.jobTitle}
        </p>


        </div>
        {form?.basic?.bio && form?.basic?.bio !== 'null' ? (
          <div className="w-[270px] rounded-2xl p-4">
            <h1 className='text-xl' style={{ fontFamily: "Poppins-Light", color: textColor }}>About Me</h1>
            <p className="text-xs overflow-auto" style={{ fontFamily: "Poppins-Light", color: textColor }}>
              {form?.basic?.bio}
            </p>
          </div>
        ) : ("")}
      
        <div className='w-[270px] rounded-2xl p-4 mt-10'>
          <h1 className='text-xl ' style={{ fontFamily: "Poppins-Light", color: textColor }}>Social Media</h1>
          <div className='flex justify-center'>
            <div className='grid md:grid-cols-3 grid-cols-4 gap-3 mt-3'>
              {form?.contacts.map((item, index) => (
                <div key={index} className='w-14 flex justify-center items-center'>
                  <img
                    style={{
                      maxWidth: "100%",
                      borderRadius: "10px",
                    }}
                    src={`${item?.logo}`}
                    alt="social logo"
                  />
                </div>
              ))}
              {form?.contactsCustom.map((item, index) => (
                <div key={index} className='w-14 flex justify-center items-center'>
                  <img
                    style={{
                      maxWidth: "100%",
                      borderRadius: "10px",
                    }}
                    src={`${item?.logo}`}
                    alt="custom logo"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
   


        <div className="flex flex-col gap-3 mt-12" >
          {form?.sections.map((item, index) => {
            const url = item.media;
            const isPDF = url?.endsWith(".pdf");
        
            return (
              <div key={index} className="flex flex-col gap-2 justify-between  w-full">
                {(isPDF || (item && item.content && item.content.length > 0 && (item.content[0]?.type === 'pdf'))) ? (
                  <div className="flex flex-col items-center justify-center mb-14">
                    <h2
                      className="text-xl md:text-xl  p-4"
                      style={{ color: textColor }}
                    >
                      {item.sectionName}
                    </h2>
                    <div className='w-[250px]  rounded-2xl border-4  ' style={{backgroundColor:form?.colors.buttonsBackground,borderColor:textColor,borderRadius: "20px"}}>
                      <a href={url} target='_blank' style={{ borderRadius: "40px"}} >
                          <div className='flex justify-center  w-full   rounded-2xl shadow-lg  relative'  >
                          <div className='flex flex-row gap-5'>
                        <BsFiletypePdf
                                        size={90}
                                        className="w-full mt-5 mb-5 "
                                        color={textButtonColor}
                                      />
                    
                          </div>
                          <div className='absolute top-3 right-3'>
                          <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                          <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                          <path fill={textButtonColor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                          </svg>
                          </div>
                          </div>
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex justify-center my-3">
                      <h2 className="text-xl md:text-xl " style={{ color: textColor }}>
                        {item.sectionName}
                      </h2>
                    </div>
                    <div className="flex justify-center">
                      <video
                        className={`rounded-3xl border-4 min-w-[250px] h-[150px] aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                        style={{
                          borderColor: `${textColor}`,
                          background: `${form?.colors.buttonsBackground}`,
                        }}
                        controls

                              
                                
                              muted  // This will mute the video, enabling it to   in more browsers
                              preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                             
                      >
                        <source src={url} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        

      </div>
    </div>
  );
}

export default Template16;
