import React from 'react';
import { useSelector } from 'react-redux';
import image from '../../../assets/images/person.jpg';
import Pdfi from '../../../assets/images/PDF-icon.png';
import { FaPlus, FaWhatsapp } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { BiShare } from "react-icons/bi";
import tinycolor from 'tinycolor2';
import { BsFiletypePdf } from 'react-icons/bs';
const Template15 = () => {
  const form = useSelector(state => state.form);

  const social = [
    { icon: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><g id="Group_48386" data-name="Group 48386" transform="translate(-227 -1015)"><rect id="Rectangle_17267" data-name="Rectangle 17267" width="48" height="48" rx="10" transform="translate(227 1015)" fill="#fefe08"/><g id="snapchat-logo-7101" transform="translate(237 1026)"><path id="Path_2485" data-name="Path 2485" d="M14.168,34.005c-.079,0-.155,0-.213-.006s-.093.006-.14.006a6.216,6.216,0,0,1-3.763-1.483,5.426,5.426,0,0,0-2.148-1.1,6.932,6.932,0,0,0-1.13-.1,7.72,7.72,0,0,0-1.568.178,3.49,3.49,0,0,1-.594.085.409.409,0,0,1-.446-.32c-.066-.224-.113-.441-.159-.649-.114-.522-.2-.84-.392-.871-2.09-.322-3.324-.8-3.57-1.373A.53.53,0,0,1,0,28.2a.335.335,0,0,1,.28-.35,7.3,7.3,0,0,0,4.386-2.608,9.828,9.828,0,0,0,1.5-2.325l.007-.016a1.5,1.5,0,0,0,.143-1.256c-.269-.635-1.161-.918-1.751-1.1-.147-.046-.285-.09-.4-.134-.523-.206-1.383-.643-1.268-1.245a1.167,1.167,0,0,1,1.134-.745.809.809,0,0,1,.343.068,3.431,3.431,0,0,0,1.419.375,1.186,1.186,0,0,0,.817-.25q-.023-.418-.05-.836c-.12-1.906-.269-4.279.338-5.64A7.33,7.33,0,0,1,13.7,7.741l.563-.005a7.343,7.343,0,0,1,6.818,4.392c.607,1.361.457,3.736.337,5.644l-.006.091c-.016.259-.032.5-.044.742a1.151,1.151,0,0,0,.741.248,3.551,3.551,0,0,0,1.337-.372,1.057,1.057,0,0,1,.438-.087,1.325,1.325,0,0,1,.5.1l.008,0a.926.926,0,0,1,.7.76c.005.292-.212.728-1.279,1.15-.109.043-.249.088-.4.134-.59.187-1.481.47-1.751,1.1a1.5,1.5,0,0,0,.143,1.256l.007.016c.074.174,1.872,4.272,5.882,4.933a.336.336,0,0,1,.28.35.542.542,0,0,1-.044.183c-.244.572-1.479,1.046-3.569,1.369-.2.03-.279.347-.392.866-.047.215-.095.424-.159.645a.391.391,0,0,1-.413.309h-.033a3.35,3.35,0,0,1-.595-.075,7.816,7.816,0,0,0-1.568-.166,6.973,6.973,0,0,0-1.13.1,5.419,5.419,0,0,0-2.146,1.1,6.221,6.221,0,0,1-3.765,1.485" transform="translate(-0.001 -7.736)" fill="#fff"/></g></g></svg>, name: "www.yourwebsite.com" },
    { icon: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><g id="Group_48385" data-name="Group 48385" transform="translate(-297 -1015)"><rect id="Rectangle_17268" data-name="Rectangle 17268" width="48" height="48" rx="10" transform="translate(297 1015)" fill="#1d9bf0"/><path id="Path_106021" data-name="Path 106021" d="M46.538,23.869c10.185,0,15.727-8.388,15.727-15.727V7.392a12.176,12.176,0,0,0,2.7-2.846,12.435,12.435,0,0,1-3.146.9,5.828,5.828,0,0,0,2.4-3A13.738,13.738,0,0,1,60.767,3.8,5.349,5.349,0,0,0,56.723,2a5.628,5.628,0,0,0-5.542,5.542,2.92,2.92,0,0,0,.15,1.2A15.486,15.486,0,0,1,39.947,2.9,5.736,5.736,0,0,0,39.2,5.745a5.951,5.951,0,0,0,2.4,4.643,5.05,5.05,0,0,1-2.546-.749h0a5.474,5.474,0,0,0,4.494,5.392,4.617,4.617,0,0,1-1.5.15A2.549,2.549,0,0,1,41,15.031a5.674,5.674,0,0,0,5.242,3.894,11.309,11.309,0,0,1-6.89,2.4A4.147,4.147,0,0,1,38,21.172a14.138,14.138,0,0,0,8.538,2.7" transform="translate(270 1026)" fill="#fff" fill-rule="evenodd"/></g></svg>, name: "www.yourwebsite.com" },
    { icon: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><g id="Group_48388" data-name="Group 48388" transform="translate(-86 -1015)"><rect id="Rectangle_17265" data-name="Rectangle 17265" width="48" height="48" rx="10" transform="translate(86 1015)" fill="#106aff"/><path id="Path_106020" data-name="Path 106020" d="M89.091,26.826V14.605h4.173l.6-4.769H89.091V6.855c0-1.341.447-2.384,2.385-2.384h2.534V.149c-.6,0-2.086-.149-3.726-.149-3.577,0-6.11,2.235-6.11,6.259V9.836H80v4.769h4.173V26.826Z" transform="translate(23 1026)" fill="#fff" fill-rule="evenodd"/></g></svg>, name: "www.yourwebsite.com" },
  ];

  const pdfs = [
    { url: 'https://example.com/pdf1.pdf', name: 'PDF 1' },
    { url: 'https://example.com/pdf2.pdf', name: 'PDF 2' },
  ];

  const videos = [
    { url: 'https://media.geeksforgeeks.org/wp-content/uploads/20190616234019/Canvas.move_.mp4' },
  ];

  const mainBackgroundColor = form.colors.mainBackground;
  const buttonsBackground = form.colors.buttonsBackground;
  const textColor = tinycolor(mainBackgroundColor).isDark() ? "#ffffff" : "#000000";
  const textButtonColor = tinycolor(buttonsBackground).isDark() ? "#ffffff" : "#000000";

  return (
    <div className='w-[100%]  border-l-[12px] border-r-[12px] border-b-[25px]' style={{ borderColor: buttonsBackground,backgroundColor:mainBackgroundColor }}>
      <div
        style={{
          backgroundImage: `url(${form?.coverImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "40vh",
          zIndex: 10,
          transition: "0.5s filter linear",
        }}
      >
       
      </div>
      <div className='relative'>
        <div className='absolute top-10 -right-1'>
          <div className='flex flex-col justify-between items-center '>
            <svg xmlns="http://www.w3.org/2000/svg" width="36.526" height="65.777" viewBox="0 0 56.526 85.777">
              <path id="Path_106008" data-name="Path 106008" d="M6505.393,2060.717v85.777l-56.526-20.95v-43.481Z" transform="translate(-6448.867 -2060.717)" fill={buttonsBackground} />
              <g transform="translate(15, 30)">
                <FaPhone size={24} color={textButtonColor} />
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="36.526" height="65.777" viewBox="0 0 56.526 85.777" className='-mt-5'>
              <path id="Path_106007" data-name="Path 106007" d="M6448.867,2060.717v85.777l56.526-20.95v-43.481Z" transform="translate(-6448.867 -2060.717)" fill={buttonsBackground} />
              <g transform="translate(15, 30)">
                <MdMail size={24} color={textButtonColor} />
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="36.526" height="65.777" viewBox="0 0 56.526 85.777" className='-mt-5'>
              <path id="Path_106008" data-name="Path 106008" d="M6505.393,2060.717v85.777l-56.526-20.95v-43.481Z" transform="translate(-6448.867 -2060.717)" fill={buttonsBackground} />
              <g transform="translate(15, 30)">
                <FaWhatsapp size={24} color={textButtonColor} />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <div className='-mt-12 w-28'>
          <img src={form?.profileImage} alt="" className='w-28 h-28 rounded-[100px] mx-auto' />
        </div>
        <div className='mt-2'>
          <h1 className='p-2 text-3xl font-bold' style={{ fontFamily: "Helvetica", color: textColor }}>{form?.basic?.firstName !== null &&
            form?.basic?.firstName !== "null"? form?.basic?.firstName :''}
            {"\n"}
            {form?.basic?.lastName !== null &&
              form?.basic?.lastName !== "null"? form?.basic?.lastName :''}</h1>
          <p className='text-center text-sm' style={{ fontFamily: "Helvetica", color: textColor }}>{form?.basic?.businessName}</p>
        </div>
        <div className='flex flex-row-reverse justify-center items-center w-[100%] gap-2 mt-2'>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="20.5" viewBox="0 0 22.5 28.5">
            <path id="map-marker-Filled_1_" d="M221.75,2782.75a3,3,0,1,1-3-3A3.009,3.009,0,0,1,221.75,2782.75Zm8.25,0c0,9.12-7.005,14.835-10.005,16.86a2.11,2.11,0,0,1-1.245.39,2.188,2.188,0,0,1-1.26-.39c-3-2.025-9.99-7.725-9.99-16.86a11.25,11.25,0,0,1,22.5,0Zm-5.25,0a6,6,0,1,0-6,6A6,6,0,0,0,224.75,2782.75Z" transform="translate(-207.5 -2771.5)" fill={buttonsBackground} />
          </svg>
          <p className='text-gray-500 text-xs' style={{ fontFamily: "Rubik-Light", color: textColor }}>{form?.basic?.location}</p>
        </div>
        <div className='w-[80%]'>
          <h1 className='p-2 text-sm text-center' style={{ fontFamily: "Helvetica", color: textColor }}>{form?.basic?.jobTitle}</h1>
          <p className='text-center text-sm overflow-auto mt-4' style={{ fontFamily: "Helvetica", color: textColor }}>{form?.basic?.bio}</p>
        </div>
        <div className='mt-9'>
          <div className="flex justify-center font-bold" style={{ fontFamily: "Helvetica", color: textColor }}>
            Social Media
          </div>
          <div className='grid grid-cols-4 gap-4 mt-4 pr-1 '>
            {form?.contacts.map((item, index) => {
              return (
                <div key={index}>
                  <button className="flex justify-between w-[40px] mt-2">
                    <div className="flex flex-row">
                      <div className=' flex items-center'>
                        <img
                    style={{
                    height:'40px', 
                    width: '40px', 
                      borderRadius: "8px",
                    }}
                    src={`${item?.logo}`}
                  />
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
            {form?.contactsCustom.map((item, index) => {
                return (
                  <div key={index}>
                    <button className="flex justify-between w-[40px] mt-2">
                      <div className="flex flex-row">
                        <div className=' flex items-center'>
                          <img
                      style={{
                        height:'40px', 
                      width: '40px', 
                        borderRadius: "8px",
                      }}
                      src={`${item?.logo}`}
                    />
                        </div>
                      </div>
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
   
      
      </div>
    







   








      <div className="flex flex-col gap-3 mt-12" style={{backgroundColor:mainBackgroundColor}}>
        {form?.sections.map((item, index) => {
          const url = item.media;
          const isPDF = url?.endsWith(".pdf");
      
          return (
            <div key={index} className="flex flex-col gap-2 justify-between  w-full">
              {(isPDF || (item && item.content && item.content.length > 0 && (item.content[0]?.type === 'pdf'))) ? (
                <div className="flex flex-col items-center justify-center mb-14">
                  <h2
                    className="text-xl md:text-xl  p-4"
                    style={{ color: textColor }}
                  >
                    {item.sectionName}
                  </h2>
                  <div className='w-[250px]  rounded-2xl border-4  ' style={{backgroundColor:form?.colors.buttonsBackground,borderColor:textColor,borderRadius: "20px"}}>
                    <a href={url} target='_blank' style={{ borderRadius: "40px"}} >
                        <div className='flex justify-center  w-full   rounded-2xl shadow-lg  relative'  >
                        <div className='flex flex-row gap-5'>
                      <BsFiletypePdf
                                      size={90}
                                      className="w-full mt-5 mb-5 "
                                      color={textButtonColor}
                                    />
                  
                        </div>
                        <div className='absolute top-3 right-3'>
                        <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                        <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                        <path fill={textButtonColor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                        </svg>
                        </div>
                        </div>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <div className="flex justify-center my-3">
                    <h2 className="text-xl md:text-xl " style={{ color: textColor }}>
                      {item.sectionName}
                    </h2>
                  </div>
                  <div className="flex justify-center">
                    <video
                      className={`rounded-3xl border-4 min-w-[250px] h-[150px] aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                      style={{
                        borderColor: `${textColor}`,
                        background: `${form?.colors.buttonsBackground}`,
                      }}
                      controls

                              
                                
                              muted  // This will mute the video, enabling it to   in more browsers
                              preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                             
                    >
                      <source src={url} type="video/mp4" />
                    </video>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>


    </div>
  );
}

export default Template15;
