import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import TherdSection from "./Sections/TherdSection.js";
import FirstSection from "./Sections/FirstSection.js";
import SecondSection from "./Sections/SecondSection.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, createTheme, ThemeProvider, CircularProgress } from "@mui/material";
import { setColors, setTheme, setProfileImage, setBasicInfo, setContacts, setCustomContacts, setCoverImage, setSections, setID, setIsPersonal, setSelectedLanguage } from "../../Redux/Slices/FormSlice.js";
import ModalStep from "../../Components/ProfileCreate/ModalStep.js";
import gsap from "gsap"; // Make sure you have gsap installed
import { MdOutlineShare, MdOutlineArticle } from "react-icons/md"; // Example icons
import { motion } from "framer-motion";

import { Snackbar, Alert } from '@mui/material'; 
import { useLocation } from "react-router-dom";

const MiniNavBar = ({ activeSection, setActiveSection }) => {
  const { t } = useTranslation();
  const [position, setPosition] = useState({ left: 0, width: 0 }); // For animated background

  const handleChange = (index, element) => {
    setActiveSection(index);

    const { offsetLeft, offsetWidth } = element;
    setPosition({ left: offsetLeft, width: offsetWidth });
  };

  useEffect(() => {
    setTimeout(() => {
      const firstTabElement = document.querySelector('ul li'); // Get the first <li> element
      if (firstTabElement) {
        handleChange(0, firstTabElement); // Set the first tab as active
      }
    }, 0); // Ensures the DOM is ready before accessing the first tab
  }, []);

  const sections = ["Personal", "Media", "Colors"];
  return (
    <div
      className="relative flex w-[100vw] p-1 mb-10"
      style={{
        backgroundColor: '#fff', // Use userData background color
       
        borderBottom: `2px solid #D9A31F`, // Border for the bottom
        position: 'sticky', // Make it sticky
        top: '0', // Stick to the top of the screen
        zIndex: '100', // Ensure it's above other content
      }}
    >
      <ul className="relative flex justify-around w-full z-10">
        {sections.map((label, index) => (
          <li
            key={label}
            className="relative flex items-center cursor-pointer px-4 py-2 text-center transition-all duration-300"
            onClick={(e) => handleChange(index, e.currentTarget)}
            style={{
              color: activeSection === index ? '#fff' : '#000',
              fontWeight: activeSection === index ? "bold" : "normal",
            }}
          > 
            {/* Replace icons with your actual icons as needed */}
             {index +1}  
            <span className="ml-2">  {label} </span>
          </li>
        ))}
      </ul>

      {/* Animated cursor with background effect */}
      <motion.div
        animate={position}
        className="absolute top-0 bottom-0 z-0 rounded-[3px]"
        style={{
          left: position.left,
          width: position.width,
          height: "100%",
          backgroundColor: '#D9A31F', // Use userData button color for the cursor background
        }}
      />
    </div>
  );
};

const Main = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(location.state?.activeSection || 0); // Default to 0

    const [modalOpen, setModalOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [profile, setProfile] = useState(localStorage.getItem("profile"));
  const form = useSelector((state) => state.form);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const fontFamily = lang === 'ar' ? 'system-ui' : 'Robote_bold';
  const [snackbarOpen, setSnackbarOpen ] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // Can be 'error', 'warning', 'info', 'success'
useEffect(() => {
  // If location.state.activeSection changes, update activeSection
  console.log(location.state?.activeSection,'location.state?.activeSection')
  if (location.state?.activeSection !== undefined) {
    setActiveSection(location.state.activeSection);
    
  }
}, [location.state]);


const firstSectionCustomTheme = createTheme({
    
    
    palette: {
   mode: "light",
   primary: {
     main: "#000",
   }, 
   secondary: {
     main: "#D9A31F",
   },
   lighter: {
     main: "#497cbe",
   },
 },
 typography: {
   fontFamily: `${fontFamily}`, // Use dynamic fontFamily

   title: {
    
     fontSize: "0.9rem",
     display: "block",
   },
   linkSelected: {
     backgroundColor: "#fff",
     fontSize: "0.9rem",
     display: "block",
   },
 },

 components: {
   MuiTextField: {
     defaultProps: {
     
       InputLabelProps: {
         style: {
           textAlign: lang === 'ar' ? 'right' : 'left',  
           right: lang === 'ar' ? 0 : 'auto',            
           left: lang === 'ar' ? 'auto' : 0,            
           direction: lang === 'ar' ? 'rtl' : 'ltr',     
           fontSize: '1rem',                            
           color: '#000',   
                                    
         },
       },
    
       InputProps: {
         inputProps: {
           style: {
             textAlign: lang === 'ar' ? 'right' : 'left',
             direction: lang === 'ar' ? 'rtl' : 'ltr',    
             color: '#000',             
           },
         },
       },
     },
   },
 },
});

  useEffect(() => {
    fetchAndDispatchProfileData();
  }, []);
  const fetchAndDispatchProfileData = async () => {
    if (profile == null || profile == 'null') return;
    try {
      const { data } = await axios.get(`https://api.x-tag.tech/api/profile/${profile}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const formData = data.data;
      dispatch(setColors({ buttonsBackground: formData.buttonColor, mainBackground: formData.bgColor }));
      dispatch(setTheme({ theme: formData.theme.id }));
      console.log(formData.firstName,'formData.firstName')
      localStorage.setItem("theme", formData?.theme);

      dispatch(setSelectedLanguage({ SelectedLanguage: localStorage.getItem("lang") }));
      dispatch(setID({ id: formData.id }));
      dispatch(setIsPersonal({ isPersonal: formData.isPersonal }));
      dispatch(setCoverImage({ coverImage: formData.cover }));
      dispatch(setProfileImage({ image: formData.photo }));
      
      dispatch(setBasicInfo({
        basic: { 
          firstName: formData.firstName || "", lastName: formData.lastName || "", jobTitle: formData.jobTitle || '', 
          businessName: formData.businessName, location: formData.location, bio: formData.bio || "", 
          phoneNumber: formData.phoneNum, email: formData.email, phoneNumSecondary: formData.phoneNumSecondary, phoneNumberWA: formData.phoneNumberWA }
      }));
      formData.primary_links.forEach(contact => {
        dispatch(setContacts({ type: contact.name, value: contact.value, idInthback: contact.id, logo: contact.logo }));
      });
      formData?.second_links?.forEach((customContact, index) => {
        dispatch(
          setCustomContacts({
            id: index , 
            link: customContact.link,
            logo: customContact.logo,
            name_link: customContact.name_link,
          })
        );
      });
      const sectionsToAdd = formData.section.map((section) => ({
        id: section.id,
        sectionName: section.title,
        media: section.media,
        contentType: "video",
      }));
      dispatch(setSections(sectionsToAdd));
    } catch (error) {
      console.log(error);
    }
  };


  const isBase64 = (str) => {
   
    const regex = /^data:image\/([a-zA-Z]*);base64,([^\"]*)$/;
    return regex.test(str);
  };
  function base64ToFile(base64String, fileName, fileType) {
    if (!base64String) {
      console.error("base64String is undefined or null");
      return null;
    }

    const base64WithPrefix = base64String.startsWith(`data:${fileType};base64,`)
      ? base64String
      : `data:${fileType};base64,${base64String}`;

    const base64Content = base64WithPrefix.split(";base64,").pop();
    try {
      const byteCharacters = atob(base64Content);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; slice.length > i; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new File(byteArrays, fileName, { type: fileType });
    } catch (error) {
      console.error("Error converting Base64 to File:", error);
      return null;
    }
  }

  const handleSubmit = async () => {
    console.log(activeSection,'activeSectionactiveSectionactiveSectionactiveSection')
    const formData = new FormData();
    setIsLoading(true);
    switch (activeSection) {
      case 0:
        appendAndSubmitBasicInfo(formData);
        break;
      case 1:
        const sortedContacts = form?.contacts.slice().sort((a, b) => a.id - b.id);
        appendAndSubmitContactInfo(formData, sortedContacts);
        break;
      case 2:
        appendAndSubmitColorInfo(formData);
        break;
      default:
        break;
    }
   
  };

  const appendAndSubmitBasicInfo = (formData) => {
   
   // formData.append("phoneNumSecondary", form.basic.phoneNumSecondary ||null);
    if(form.isPersonal=="true"){
      formData.append("firstName", form.basic.firstName );
      formData.append("lastName", form.basic.lastName);  
    }
    if (isNaN(form?.theme) || form?.theme === null || form?.theme === 'null' || form?.theme === undefined || form?.theme === 'undefined' || form?.theme === -1) {
      formData.append("theme_id", 1);
    } else {
      formData.append("theme_id", Number(form?.theme));
    }

    console.log(form?.theme,'form?.themeform?.themeform?.themeform?.theme')
    if (form.coverImage !== null && form.coverImage !== "null") {
      if (isBase64(form.coverImage)) {
        formData.append(
          "cover",
          base64ToFile(form.coverImage, "image.png", "image/png")
        );
      }
    } else {
      formData.append("cover", "");
    }
    if (form.profileImage !== null && form.profileImage !== "null") {
      if (isBase64(form.profileImage)) {
        formData.append(
          "photo",
          base64ToFile(form.profileImage, "image.png", "image/png")
        );
      }
    } else {
      formData.append("photo", "");
    }
        formData.append("isPersonal", form.isPersonal ?? "true");
        if(profile==null || profile=='null'){
          formData.append("phoneNumberWA", form.basic.phoneNumber);
          dispatch(setBasicInfo({ basic: { ...form.basic, phoneNumberWA: form.basic.phoneNumber } }));

        }
      else{
        formData.append("phoneNumberWA", form.basic.phoneNumberWA);
      }

    formData.append("phoneNum", form.basic.phoneNumber);
    formData.append("email", form.basic.email);
    formData.append("SelectedLanguage", localStorage.getItem('lang'));

    formData.append("bio", form.basic.bio);
    formData.append("location", form.basic.location);
    form.contactsCustom.forEach((contact, index) => {
      formData.append(`secondLinks[${index}][name_link]`, contact.idInthback );
      formData.append(`secondLinks[${index}][link]`, contact.link);
    });
  // form.sections.forEach((section, index) => {
  //   if (section?.media) formData.append(`sections[${index}][media]`, section.media);
  //   formData.append(`sections[${index}][title]`, section.title);
  // }); 

    formData.append("jobTitle", form.basic.jobTitle );
    formData.append("businessName", form.basic.businessName || null);
    if(profile !==null && profile !=='null'){
      makeApiCall(formData, profile );
    }
    if(profile == null || profile =='null'){
      makeApiCall(formData, "create_personal_data");

    };
  }
  // const appendAndSubmitContactInfo = (formData, sortedContacts) => {
  //   sortedContacts.forEach((contact, index) => {
  //     formData.append(`primaryLinks[${index}][id]`, contact.idInthback );
  //     formData.append(`primaryLinks[${index}][value]`, contact.value);
  //   });
  //   makeApiCall(formData, profile);

  // }
  async function appendAndSubmitContactInfo(formData, sortedContacts) {
    // Append primary and secondary contacts
    sortedContacts?.forEach((contact, index) => {
      if (contact.value !== "") {
        formData.append(
          `primaryLinks[${index}][id]`,
          contact.idInthback !== null ? contact.idInthback : index
        );
        formData.append(`primaryLinks[${index}][value]`, contact.value);
      }
    });

    form?.contactsCustom
      .filter((contact) => contact.name || contact.link)
      .filter((contact) => contact.image || contact.logo)
      .forEach((contact, index) => {
        formData.append(`secondLinks[${index}][value]`, contact.name);
        formData.append(`secondLinks[${index}][name_link]`, contact.type);
        formData.append(
          `secondLinks[${index}][link]`,
          contact.name ? contact.name : contact.link
        );

        if (isBase64(contact?.image)) {
          formData.append(
            `secondLinks[${index}][logo]`,
            base64ToFile(contact.image, "image.png", "image/png")
          );
        } else {
          formData.append(`secondLinks[${index}][logo]`, contact.logo);
        }
      });

    form.sections.forEach((section, index) => {
      if (
        (section?.media !== undefined &&
          section?.media !== null &&
          section?.media !== "undefined" &&
          section?.media !== "null" &&
          section?.media !== "" &&
          section?.sectionName !== "") ||
        (section?.content && section?.sectionName !== "")
      ) {
        if (section?.media !== null) {
          formData.append(`sections[${index}][media]`, section.media);
        } else {
          formData.append(
            `sections[${index}][media]`,
            base64ToFile(
              section.content[0].value,
              `section${index}Media`,
              section.contentType === "pdf" ? "application/pdf" : "video/mp4"
            )
          );
        }
        formData.append(`sections[${index}][title]`, section.sectionName);
        formData.append(
          `sections[${index}][name_of_file]`,
          section.sectionName
        );
      }
    });

    if (profile == null || profile == "null") {
      await makeApiCall(formData, "create_links");
    } else {
      await makeApiCall(formData, profile);
    }
  }
  const appendAndSubmitColorInfo = (formData) => {
    formData.append("bgColor", form.colors.mainBackground || "#fff");
    formData.append("buttonColor", form.colors.buttonsBackground || "#000");
    formData.append("theme_id", form?.theme === -1 ? 1 : localStorage.getItem("theme"));
    if (form.profileImage !== null && form.profileImage !== "null") {
      if (isBase64(form.profileImage)) {
        formData.append(
          "photo",
          base64ToFile(form.profileImage, "image.png", "image/png")
        );
      }
    } else {
      formData.append("photo", "");
    }
    makeApiCall(formData, profile);
  };

 

  async function makeApiCall(formData, api) {
   
    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://api.x-tag.tech/api/profile/${api}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }, timeout: 600000 // setting timeout of 10 seconds
        }
      );
      console.log("Response:", response.data);
      if (api == "create_personal_data") {
        setSnackbarMessage(t('Your profile has been successfully created!'));
      } else {
        setSnackbarMessage(t('Profile updated successfully!'));
      }
      setSnackbarSeverity('success');
     if(profile==null || profile=='null'){
      // makeApiCall(formData2, response.data.id)
      console.log(api,'api')
      console.log(response?.data?.id,'api')

      setProfile(response?.data?.id)

      const formData2 = new FormData();

    form?.contactsCustom
    .forEach((contact, index) => {
      formData2.append(`secondLinks[${index}][name_link]`, contact.name_link);
      formData2.append(
        `secondLinks[${index}][link]`,
        contact.name_link
      );
    });
    formData2.append("bgColor", "#fff");
    formData2.append("buttonColor", "#000");
    formData2.append("phoneNumberWA", form.basic.phoneNumber);

    const response2 = await axios.post(
      `https://api.x-tag.tech/api/profile/${response.data.id}`,
      formData2,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:` Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("Response2:", response2.data);

    localStorage.setItem('profile',response.data.id)



     }
     
     
      console.log("activeSection", activeSection);
      if ( profile !== null && profile !== "null") {
        setActiveSection(activeSection);
        setSnackbarOpen(true)
    setSnackbarMessage('all good !')

        console.log("activeSection", activeSection);
      }
      //  else
     else   if (profile == null || profile == "null") {
        // setShowModal(true);
        console.log(response.data?.profile)
        localStorage.setItem('profile',response.data?.id)
        localStorage.setItem('theme',1)

        console.log("activeSection", activeSection);
      } else {
        console.log("activeSection", activeSection);
        setActiveSection((prevactiveSection) => prevactiveSection + 1);
        setSnackbarOpen(true)
    setSnackbarMessage('all good !')

      }

    } catch (error) {
      let errorMessage = t("An unexpected error occurred. Please try again."); 
  
      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = t("There seems to be a problem with the data you submitted.");
            break;
          case 401:
            errorMessage = t("You are not authorized. Please log in and try again.");
            break;
            case 422:
              errorMessage = t("You info are incorrect.. Please check and try again.");
              break;
          case 404:
            errorMessage = t("The requested resource was not found.");
            break;
          case 500:
            errorMessage = t("Our server is having issues, please try again later.");
            break;
          default:
            errorMessage = t("Server responded with an error: ") + error.response.status;
            break;
        }
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
      } else if (error.request) {
        // The request was made but no response was received
        setSnackbarMessage(t("No response from the server. Check your internet connection or try again later."));
        setSnackbarSeverity('error');
      } else {
        // Something happened in setting up the request that triggered an Error
        setSnackbarMessage(error.message || t("An error occurred while making the request."));
        setSnackbarSeverity('error');
      }
    } finally {
      setIsLoading(false);
      setSnackbarOpen(true);
    }
  }


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const renderSection = () => {
    switch (activeSection) {
      case 0:
        return <FirstSection handleSubmit={handleSubmit} isLoading={isLoading} />;
      case 1:
        return <SecondSection handleSubmit={handleSubmit}  isLoading={isLoading} />;
      case 2:
        return <TherdSection  handleSubmit={handleSubmit} isLoading={isLoading} />;
      default:
        return <FirstSection handleSubmit={handleSubmit} isLoading={isLoading} />;
    }
  };
  const toggleTemplatePreview = () => {
    if (!isExpanded) {
      // Animate the showing of TemplatePreview
      gsap.to(".template-preview", {
        height: "50vh", // Set height to 50% of the viewport
        width: "100vw", // Set width to 100% of the viewport
        opacity: 1,
        duration: 0.8,
        ease: "power2.out",
      });
    } else {
      // Animate the hiding of TemplatePreview
      gsap.to(".template-preview", {
        height: 0,
        width: "0",
        opacity: 0,
        duration: 0.8,
        ease: "power2.in",
      });
    }
    setIsExpanded(!isExpanded); // Toggle the state
  };

  return (
    <ThemeProvider theme={firstSectionCustomTheme}>
      <>
        {profile == null || profile == "null" ? (
        <MiniNavBar activeSection={activeSection} setActiveSection={setActiveSection} />
        ) : (''
        )}
          <Box sx={{ width: "100%", height: "100%", marginTop: "10px", overflowX: "hidden" }}>
        
           {renderSection()}
          </Box>
       

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    </ThemeProvider>
  );
};

export default Main;