import { motion } from 'framer-motion';
import styles from './style.module.css';
import { IoPhonePortraitOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function Button({isActive, toggleMenu}) {
    let lang = localStorage.getItem('lang')

  return (
    <div className={styles.button} style={{zIndex: 699}}>
        <motion.div 
            className={styles.slider}
            animate={{top: isActive ? "-100%" : "0%"}}
            transition={{ duration: 0.5, type: "tween", ease: [0.76, 0, 0.24, 1]}}
        >
            <div 
                className={styles.el}
                onClick={() => {toggleMenu()}}
            >
            <div className={styles.perspectiveText}>
            <IoPhonePortraitOutline size={40} color={'#fff'}/>
            </div>
              

            </div>
            <div 
                className={styles.el}
                onClick={() => {toggleMenu()}}
            >
            <div className={styles.perspectiveText}>
            <AiOutlineCloseCircle size={40} color={'#fff'}/>
            </div>            </div>
        </motion.div>
    </div>
  )
}

