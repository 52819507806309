import "./App.css";
import MainRoutes from "./Routes/Routes";
import {
  Box,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { useEffect, useState } from "react";
import { useLanguage } from "./hook/useLocalStorage";
import { useDispatch } from "react-redux";
import { setSelectedLanguage } from "./Redux/Slices/FormSlice";
import axios from "axios";
import Navigation from "./Components/Navigation/Navigation";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();

  const [languagee, setLanguagee] = useState(lang);
  const { setLanguage } = useLanguage();
  const fontFamily = lang === "ar" ? "system-ui" : "Roboto_bold";
  const profile = localStorage.getItem("profile");
  const token = localStorage.getItem("token");

  useEffect(() => {
    i18n.changeLanguage(languagee);
  }, [languagee]);

  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#D9A31F",
      },
      secondary: {
        main: "#705ea8",
      },
      lighter: {
        main: "#497cbe",
      },
    },
    typography: {
      fontFamily: `${fontFamily}`,
      title: {
        color: "#fff",
        fontSize: "0.9rem",
        display: "block",
      },
      linkSelected: {
        color: "#fff",
        backgroundColor: "#fff",
        fontSize: "0.9rem",
        display: "block",
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            style: {
              textAlign: lang === "ar" ? "right" : "left",
              right: lang === "ar" ? 0 : "auto",
              left: lang === "ar" ? "auto" : 0,
              direction: lang === "ar" ? "rtl" : "ltr",
              fontSize: "1rem",
              color: "#000",
            },
          },
          InputProps: {
            inputProps: {
              style: {
                textAlign: lang === "ar" ? "right" : "left",
                direction: lang === "ar" ? "rtl" : "ltr",
                color: "#000",
              },
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang === "en" || lang === "ar") {
      setLanguagee(lang);
      setLanguage(lang);
    } else {
      localStorage.setItem("lang", "en");
      setLanguagee("en");
      setLanguage("en");
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(languagee);
    setLanguagee(languagee);
    setLanguage(languagee);
    dispatch(setSelectedLanguage({ SelectedLanguage: languagee }));
  }, [languagee]);

  const handleLanguageToggle = async () => {
    const newLang = languagee === "en" ? "ar" : "en";
    const formData = new FormData();
    formData.append("SelectedLanguage", newLang);
    setLanguagee(newLang);
    setLanguage(newLang);

    localStorage.setItem("lang", newLang);
    i18n.changeLanguage(newLang);
  };

  const handleLogout = () => {
    navigate("/");
  };

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Navigation
          languagee={languagee}
          setLanguagee={setLanguagee}
          handleLanguageToggle={handleLanguageToggle}
          handleLogout={handleLogout}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            zIndex: 1100,
            direction: languagee === "ar" ? "rtl" : "ltr",
            marginTop: location.pathname !== "/" ? "100px" : 0,
          }}
        >
          <MainRoutes />
        </Box>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
