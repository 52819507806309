import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setColors } from "../../Redux/Slices/FormSlice";
import { useTranslation } from "react-i18next";
import { HexColorPicker } from "react-colorful";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ColorLensIcon from '@mui/icons-material/ColorLens';
const ColorPicker = ({handleSubmit}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useSelector(state => state.form);
  const [openModal, setOpenModal] = useState(false);
  const [customBgColor, setCustomBgColor] = useState(colors.mainBackground);
  const [customBtnColor, setCustomBtnColor] = useState(colors.buttonsBackground);
  const [selectedPaletteIndex, setSelectedPaletteIndex] = useState(-1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const index = backgroundPalettes.findIndex((palette, idx) =>
      palette === colors.mainBackground && buttonPalettes[idx] === colors.buttonsBackground);
    setSelectedPaletteIndex(index !== -1 ? index : -1);
  }, [colors]);

  const handlePaletteChange = (bgColor, btnColor, index) => {
    dispatch(setColors({ mainBackground: bgColor, buttonsBackground: btnColor }));
    setSelectedPaletteIndex(index);

  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setCustomBgColor(colors.mainBackground);
    setCustomBtnColor(colors.buttonsBackground);
  };

  const handleCloseModal = () => setOpenModal(false);

  const applyCustomColors = () => {
    dispatch(setColors({ mainBackground: customBgColor, buttonsBackground: customBtnColor }));
    handleCloseModal()
};
const backgroundPalettes = [
  "#fff",
  "#f0f7ee",
  "#c5c5c5",
  "#3f3f37",
  "#393d3f",
  "#080708",
  "#f6d8af",
  "#b7ad99",
  "#c5afa4",
  "#002a32",
  "#ef798a",
  "#785964",
  "#2e3532",
  "#463f3a",
  "#595959",
  "#0d1321",
  "#cecccc",
  "#ff5e5b",
  "#db5461",
  "#0a2342",
  "#a49e8d",
  "#ada8b6",
  "#32292f",
  "#c1c1c1",
  "#70d6ff",
  "#eaebed",
  "#080705",
  "#ebbab9",
  "#05a8aa",
  "#8c271e",
];
const buttonPalettes = [
  "#000",
  "#c4d7f2",
  "#4c5b61",
  "#d6d6b1",
  "#fdfdff",
  "#3772ff",
  "#2e4057",
  "#ff4365",
  "#cc7e85",
  "#c4a29e",
  "#f7a9a8",
  "#82a7a6",
  "#8b2635",
  "#8a817c",
  "#8a817c",
  "#ffeddf",
  "#9d6381",
  "#d8d8d8",
  "#ffd9ce",
  "#2ca58d",
  "#504136",
  "#ffeedb",
  "#575366",
  "#2c4251",
  "#ff70a6",
  "#006989",
  "#40434e",
  "#c9c5ba",
  "#b8d5b8",
  "#aba194",
];
  return (
    <>
      <Box sx={{ bgcolor: 'rgba(247, 247, 247, 0.8)', p: 2, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          {t("Our_suggestions")}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {backgroundPalettes.map((bgColor, index) => (
            <Grid item key={index} xs={4} sm={2}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 80,
                width: 80,
                borderRadius: '50%',
                cursor: 'pointer',

                background: `linear-gradient(140deg, ${bgColor} 50%, ${buttonPalettes[index]} 50%)`,
                border: selectedPaletteIndex === index ? "2px solid #6B73FF" : "1px solid #ccc",
                transition: 'all 0.3s ease',
                "&:hover": {
                  transform: 'scale(1.1)',
                  borderColor: "#6B73FF",
                },
                position: 'relative',
              }}
                onClick={() => handlePaletteChange(bgColor, buttonPalettes[index], index)}>
                
              </Box>
            </Grid>
          ))}
         
        </Grid>
        <Typography variant="h6" gutterBottom style={{marginTop:"15px"}}>
        {t("more_Options")}
        <Grid item xs={4} sm={2}>
        <Button
          onClick={handleOpenModal}
          sx={{
            borderRadius: "50%",
            width: 80,
            height: 80,
          
            border: selectedPaletteIndex === -1 ? "2px solid #6B73FF" : "1px solid #ccc",
            minWidth: 0,
            padding: 0,
            width: '3em',
    height: '3em',

            "&:hover": {
              borderColor: "#6B73FF",
              transform: 'scale(1.4)',
            }
          }}>
          <ColorLensIcon color="#fff" size="34px"/>
        </Button>
      </Grid>
      </Typography>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box sx={{
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
            width: isSmallScreen ? '90%' : '50%',
          }}>
            <Typography id="modal-title" variant="h6" sx={{ mb: 2 }}>
              {t("Select_Custom_Colors")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>{t("Background_Color")}</Typography>
                <HexColorPicker color={customBgColor} onChange={setCustomBgColor} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>{t("ButtonـColor")}</Typography>
                <HexColorPicker color={customBtnColor} onChange={setCustomBtnColor} />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button onClick={handleCloseModal}>{t("cancel")}</Button>
              <Button variant="contained" onClick={applyCustomColors} color="primary">{t("save")}</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default ColorPicker;