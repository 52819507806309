import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addSectionAtTop,
  setTitleSections,
  reorderSections,
  removeSection,
  addContentToSection,
  setPdfName,
} from "../Redux/Slices/FormSlice";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../hook/useLocalStorage";

const DraggableFeatured = () => {
  const [sections, setSections] = useState([]);
  const [sectionErrors, setSectionErrors] = useState([]);
  const [debouncedSectionNames, setDebouncedSectionNames] = useState({});
  const fileInputRef = useRef([]);
  const timeoutRef = useRef({});
  const [pdfToOpen, setPdfToOpen] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);
  const { tex } = useLanguage();
  
  const handleShowMedia = (section) => {
    if (typeof section.media === "string" && section.media.slice(-3) === "pdf") {
      window.open(section.media);
    } else {
      setCurrentMedia(section);
      setShowMediaModal(true);
    }
  };

  const handleCloseMediaModal = () => {
    setShowMediaModal(false);
    setCurrentMedia(null);
  };

  const sectionss = useSelector((state) => state.form.sections); // Use useSelector to get sections from Redux state
  useEffect(() => {
    setSections(
      (sectionss ?? []).map((section) => ({
        id: section.id,
        sectionName: section.sectionName,
        contentType: section.contentType,
        media: section.media ? section.media : section?.content ? section?.content[0] : "",
        pdfName: section.pdfName,
      }))
    );
  }, [sectionss]);

  const handleAddSection = (contentType) => {
    if (sections.length >= 3) {
      return;
    }

    const newSection = {
      sectionName: "",
      contentType,
      media: null,
      pdfName: "",
    };

    setSections((prevSections) => [
      newSection,
      ...prevSections,
    ]);

    dispatch(
      addSectionAtTop(newSection)
    );

    // Trigger file input for the new section
    setTimeout(() => {
      const newIndex = 0; // Index for the newly added section
      if (fileInputRef.current[newIndex]) {
        fileInputRef.current[newIndex].click();
      }
    }, 100); // Ensure state is updated before triggering the file input
  };

  const handleRemoveSection = (sectionIndex) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections.splice(sectionIndex, 1);
      dispatch(removeSection(sectionIndex));
      return updatedSections;
    });
  };

  const handleItemChange = (sectionIndex, contentType, e) => {
    const file = e?.target?.files?.[0];

    if (!file) {
      return;
    }
    const maxSize = contentType === "video" ? 25 * 1024 * 1024 : 25 * 1024 * 1024; // 25 MB limit
    if (file.size > maxSize) {
      const newErrors = [...sectionErrors];
      newErrors[sectionIndex] = "File size exceeds the limit (25 MB).";
      setSectionErrors(newErrors);
      e.target.value = "";
      return;
    }
    const newErrors = [...sectionErrors];
    newErrors[sectionIndex] = null;
    setSectionErrors(newErrors);

    const reader = new FileReader();

    reader.onload = () => {
      let base64String = btoa(
        new Uint8Array(reader.result).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      if (contentType === "pdf") {
        base64String = "data:application/pdf;base64," + base64String;
      } else if (contentType === "video") {
        base64String = "data:video/mp4;base64," + base64String;
      }

      const updatedSections = [...sections];
      dispatch(addContentToSection({ sectionIndex, contentType, media: base64String }));

      if (contentType === "video" || contentType === "pdf") {
        updatedSections[sectionIndex].media = {
          type: "media",
          value: base64String,
        };
      }

      setSections(updatedSections);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSectionNameChange = (sectionIndex, value) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].sectionName = value;
  
      // Clear the previous timeout
      if (timeoutRef.current[sectionIndex]) {
        clearTimeout(timeoutRef.current[sectionIndex]);
      }
  
      // Set a new timeout
      timeoutRef.current[sectionIndex] = setTimeout(() => {
        dispatch(setTitleSections({ sectionIndex, value }));
  
        // Update debounced section names for visual consistency
        setDebouncedSectionNames(prev => ({
          ...prev,
          [sectionIndex]: value,
        }));
      }, 1000);
  
      return updatedSections;
    });
  };

  const handleDragStartSection = (e, sectionIndex) => {
    e.dataTransfer.setData("text/plain", sectionIndex.toString());
  };

  const handleDropSection = (e, targetIndex) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData("text/plain");
    const draggedIndex = parseInt(draggedId, 10);

    if (draggedIndex === targetIndex) {
      return;
    }

    const updatedSections = [...sections];
    const [draggedSection] = updatedSections.splice(draggedIndex, 1);

    targetIndex = Math.max(0, Math.min(targetIndex, updatedSections.length));
    updatedSections.splice(targetIndex, 0, draggedSection);

    setSections(updatedSections);
    dispatch(reorderSections({ draggedIndex, targetIndex }));
  };

  useEffect(() => {
    if (pdfToOpen) {
      window.open(pdfToOpen);
      setPdfToOpen(null);
    }
  }, [pdfToOpen]);


  return (
    <div style={{ marginBottom:"100px"}}>
      <Box
        sx={{
          padding: tex?.language === "en" ? "1rem 0.5rem" : "1rem 1.3rem",
          display: "flex",
          flexDirection: { xs: "row", md: "row" },
          gap: "1rem",
          justifyContent: "space-around",
        
        }}
      >
        <Button
          onClick={() => handleAddSection("video")}
          component="label"
          variant="contained"
          startIcon={<AddIcon />}
          style={{
            background: "#262626",
            backdropFilter: "blur(8px)",
            border: "1px solid rgba(229, 205, 121, 0.5)",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
            padding: "0.4rem 0.6rem",
            borderRadius: "4px",
            color: "#fff",
            cursor: "pointer",
            width: "150px",
            marginBottom: "1rem",
            marginTop: "0.2rem",
            marginLeft: tex?.language === "ar" ? "auto" : "",
            fontSize: "1rem",
            letterSpacing: "0.5px",
            transition: "transform 0.3s ease",
          }}
        >
          {t("Video_section")}
        </Button>
        <Button
          onClick={() => handleAddSection("pdf")}
          component="label"
          variant="contained"
          startIcon={<AddIcon size={30} />}
          style={{
            background: "#262626",
            backdropFilter: "blur(8px)",
            border: "1px solid rgba(229, 205, 121, 0.5)",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
            padding: "0.4rem 0.6rem",
            borderRadius: "4px",
            color: "#fff",
            cursor: "pointer",
            width: "150px",
            marginTop: "0.2rem",
            marginBottom: "1rem",
            marginLeft: tex?.language === "ar" ? "auto" : "0",
            fontSize: "1rem",
            letterSpacing: "0.5px",
            transition: "transform 0.3s ease",
          }}
          disabled={
            sections.filter(
              (s) =>
                s.contentType === "pdf" ||
                (typeof s.media === "string" && s.media.slice(-3) === "pdf")
            ).length >= 2
          }
        >
          {t("Pdf_section")}
        </Button>
      </Box>

      <div
        className="draggable-featured"
        id="your-scrollable-div-id"
        style={{
          maxHeight: "300px",
          overflowY: "auto",
          background: "#f7f7f7",
          borderRadius: "10px",
          padding: "1rem",
        }}
      >
        {sections?.map((section, sectionIndex) => (
          <div
            key={sectionIndex}
            className="section-box"
            draggable
            onDragStart={(e) => handleDragStartSection(e, sectionIndex)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleDropSection(e, sectionIndex)}
            style={{
              padding: "0.5rem",
              borderRadius: "10px",
              margin: "2rem 0",
              background: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <label
                  htmlFor={`section_input_${sectionIndex}`}
                  style={{
                    zIndex: "10",
                    borderRadius: "25%",
                    cursor: "pointer",
                    width: "2.7rem",
                    height: "2.7rem",
                    marginTop: "0.5rem",
                    marginRight: "0.3rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: section.media ? "transparent" : "gray",
                  }}
                >
                  {section.media ? (
                    <button
                      onClick={() => handleShowMedia(section)}
                      style={{
                        background: "#262626",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "3.4rem",
                        height: "3.4rem",
                        marginTop: "0.2rem",
                        borderRadius: "25%",
                        marginRight: "0.3rem",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          fontSize: "0.8rem",
                          padding: "0.4rem",
                          textAlign: "center",
                        }}
                      >
                        {section.contentType === "video" ? "Preview" : "Preview"}
                      </span>
                    </button>
                  ) : (
                    <span
                      style={{
                        color: "white",
                        fontSize: "1.5rem",
                        textAlign: "center",
                      }}
                    >
                      +
                    </span>
                  )}
                  <input
                    ref={(el) => (fileInputRef.current[sectionIndex] = el)}
                    id={`section_input_${sectionIndex}`}
                    type="file"
                    accept={section.contentType === "video" ? "video/*" : ".pdf"}
                    onChange={(e) => handleItemChange(sectionIndex, section.contentType, e)}
                    style={{ display: "none" }}
                    className="bg-text"
                  />
                </label>

                <TextField
                  size="small"
                  id={`section_name_${sectionIndex}`}
                  label="Title"
                  variant="outlined"
                  value={section?.sectionName}
                  onChange={(e) => handleSectionNameChange(sectionIndex, e.target.value)}
                  style={{ width: "100%", marginTop: "0.53rem" }}
                  className={`bg-text custom-gray-border ${
                    sectionErrors[sectionIndex] ? "error-border" : ""
                  }`}
                  InputLabelProps={{
                    style: { color: 'gray' }, // Label color
                  }}
                  InputProps={{
                    style: {
                      color: 'gray', // Input text color
                    }
                  }}
                />
              </div>

              <button
                onClick={() => handleRemoveSection(sectionIndex)}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "darkgray", // Dark gray color for the close button
                  cursor: "pointer",
                  fontSize: "1.4rem",
                  marginLeft: "0.5rem", // Added margin for spacing
                  marginTop:'10px'
                }}
              >
                X
              </button>
            </div>
            {sectionErrors[sectionIndex] && (
              <div className="text-red-500">{sectionErrors[sectionIndex]}</div>
            )}
          </div>
        ))}

        {showMediaModal && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 100,
            }}
          >
            <div
              style={{
                position: "relative",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                maxWidth: "80%",
                maxHeight: "80%",
                overflowY: "auto",
              }}
            >
              {(typeof currentMedia?.media === "string" &&
                currentMedia?.media?.slice(-3) === "mp4") ||
              (typeof currentMedia?.media === "object" &&
                currentMedia?.contentType === "video") ? (
                <div>
                  <video controls

                              
                                
                              muted  // This will mute the video, enabling it to   in more browsers
                              preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                              style={{ width: "100%", maxHeight: "500px" }}>
                    <source
                      src={
                        typeof currentMedia.media === "string"
                          ? currentMedia.media
                          : currentMedia.media.value
                      }
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (typeof currentMedia.media === "object" &&
                currentMedia?.contentType === "pdf") ? (
                <iframe
                  style={{
                    width: "100%",
                    height: "50vh",
                    maxHeight: "500px",
                    minHeight: "200px",
                    overflowY: "auto",
                  }}
                  src={
                    typeof currentMedia.media === "string"
                      ? currentMedia.media
                      : currentMedia.media.value
                  }
                  title="PDF"
                ></iframe>
              ) : ""}
              <button
                onClick={handleCloseMediaModal}
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  border: "none",
                  background: "none",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
                className="text-black"
              >
                X
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DraggableFeatured;
