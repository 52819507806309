import React from 'react';
import { useSelector } from 'react-redux';
import image from '../../../assets/images/person.jpg';
import Pdfi from '../../../assets/images/PDF-icon.png';
import message from '../../../assets/images/message.png';
import phone from '../../../assets/images/phone.png';
import whatsapp from '../../../assets/images/whatsapp.png';
import { BsShareFill } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io5';
import tinycolor from 'tinycolor2';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MdEmail, MdOutlineSaveAlt } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa";
import { BsFiletypePdf } from 'react-icons/bs';
const Template12 = () => {
  const [textcolor, setTextColor] = useState("#ffffff");
  const form = useSelector(state => state.form);
 
  const social = [
    { icon: <svg xmlns="http://www.w3.org/2000/svg" width="29.703" height="24.108" viewBox="0 0 29.703 24.108"><path id="Twitter" d="M26.2,3.812A6.107,6.107,0,0,0,28.883.445a12.23,12.23,0,0,1-3.87,1.477A6.1,6.1,0,0,0,14.629,7.473,17.3,17.3,0,0,1,2.068,1.114,6.087,6.087,0,0,0,3.953,9.239a6.076,6.076,0,0,1-2.76-.761c0,.025,0,.05,0,.076a6.092,6.092,0,0,0,4.888,5.968,6.107,6.107,0,0,1-2.752.1,6.1,6.1,0,0,0,5.693,4.226,12.237,12.237,0,0,1-7.568,2.606A12.225,12.225,0,0,1,0,21.373a17.258,17.258,0,0,0,9.342,2.735c11.209,0,17.339-9.275,17.339-17.317q0-.395-.018-.787A12.359,12.359,0,0,0,29.7,2.853,12.2,12.2,0,0,1,26.2,3.812Z" fill="#9E9E9E" /></svg>, value: "twitter" },
    { icon: <svg xmlns="http://www.w3.org/2000/svg" width="12.596" height="25.176" viewBox="0 0 12.596 25.176"><path id="Facebook" d="M12.6,8.151H8.308V5.539A1.106,1.106,0,0,1,9.5,4.329h3.027V.015L8.358,0C3.729,0,2.676,3.218,2.676,5.277V8.151H0V12.6H2.676V25.176H8.308V12.6h3.8Z" fill="#9E9E9E" /></svg>, value: "facebook" },
  ];

  const pdfs = [
    { url: 'https://example.com/pdf1.pdf', name: 'PDF 1' },
    { url: 'https://example.com/pdf2.pdf', name: 'PDF 2' },
  ];

  const videos = [
    { url: 'https://media.geeksforgeeks.org/wp-content/uploads/20190616234019/Canvas.move_.mp4' },
  ];

  const mainBackgroundColor = form.colors.mainBackground;
  const buttonsBackground = form.colors.buttonsBackground;
  const textColor = tinycolor(mainBackgroundColor).isDark() ? "#ffffff" : "#000000";
  const textButtonColor = tinycolor(buttonsBackground).isDark() ? "#ffffff" : "#000000";

  return (
    <div className='h-screen w-[100%]' style={{ backgroundColor: mainBackgroundColor }}>
      <div>
        <div style={{
          backgroundImage: `url(${form?.coverImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: '40vh',
          width: "100%",
          borderBottomLeftRadius: '50% 15%',
          borderBottomRightRadius: '50% 15%',
        }}>
          <div className='flex justify-end pt-2 mr-2'>
            
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" ,backgroundColor: mainBackgroundColor}}>
        <div className='flex justify-center  -mt-10 w-32'>
          <img src={form?.profileImage} alt="" className='w-32 h-32 rounded-full' />
        </div>
        <div className='mt-2'>
          <h1 className='p-3 text-3xl' style={{ fontFamily: "Rubik-Light", color: textColor }}> {form?.basic?.firstName !== null &&
            form?.basic?.firstName !== "null"? form?.basic?.firstName :''}
            {"\n"}
            {form?.basic?.lastName !== null &&
              form?.basic?.lastName !== "null"? form?.basic?.lastName :''} </h1>
          <p className='text-center text-sm' style={{ fontFamily: "Rubik-Light", color: textColor }}>{form?.basic?.businessName}</p>
          <p className='text-center text-sm' style={{ fontFamily: "Rubik-Light", color: textColor }}>{form?.basic?.jobTitle}</p>
        </div>
        <div className='flex justify-center items-center gap-2 mt-4'>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="20.5" viewBox="0 0 22.5 28.5">
            <path  id="map-marker-Filled_1_" d="M221.75,2782.75a3,3,0,1,1-3-3A3.009,3.009,0,0,1,221.75,2782.75Zm8.25,0c0,9.12-7.005,14.835-10.005,16.86a2.11,2.11,0,0,1-1.245.39,2.188,2.188,0,0,1-1.26-.39c-3-2.025-9.99-7.725-9.99-16.86a11.25,11.25,0,0,1,22.5,0Zm-5.25,0a6,6,0,1,0-6,6A6,6,0,0,0,224.75,2782.75Z" transform="translate(-207.5 -2771.5)" fill={buttonsBackground} />
          </svg>
          <p className='text-xs' style={{ fontFamily: "Rubik-Light", color: textColor }}>{form?.basic?.location}</p>
        </div>
        <Grid my={"1rem"} gap={"3rem"} justifyContent={"center"} container>
             
          <Grid
            item
            sx={{
              width: "40px",
              height:'40px',
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.5rem",
              border:'2px solid ',
              borderColor:textColor,
            }}
          >
            <button className='text-[20px]'>
          <IoLogoWhatsapp color={textColor}/>
        </button>
          </Grid>
        
      
          <Grid
            item
            sx={{
              width: "40px",
              height:'40px',
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.5rem",
              cursor: "pointer", // Add this line to show it's clickable
              border:'2px solid ',
              borderColor:textColor,
            }}
            
          >
            <button className="text-[20px]">
            <MdEmail color={textColor}/>
            </button>
          </Grid>
       
          <Grid
            item
            sx={{
              width: "40px",
              height:'40px',
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.5rem",
              cursor: "pointer", // Add this line to show it's clickable
              border:'2px solid ',
              borderColor:textColor,
            }}
          >
            <button className="text-[20px] z-10 ">
              <FaPhoneVolume color={textColor} className=' -rotate-[30deg]' />
                              </button>
          </Grid>
       
      </Grid>
        <div className="rounded-full py-1 px-4 mx-auto mt-5 w-[200px]" style={{ backgroundColor: buttonsBackground }}>
          <p className='text-center' style={{ fontFamily: "Rubik-Light", color: textButtonColor }}>Add To Contact</p>
        </div>
        {form?.basic?.bio?
          <div className="rounded-2xl px-14 py-4 mt-1">
            <h1 className="text-2xl font-bold" style={{ color: textColor }}>
              About Me
            </h1>
            <p className="text-xs w-[110%]" style={{ fontFamily: "Rubik-Light", color: textColor }}>
              {form?.basic?.bio}
            </p>
          </div>
          :""}
       
        <div className='rounded-2xl p-4 mt-10' style={{backgroundColor:mainBackgroundColor}}>
          <h1 className="text-2xl font-bold ml-3 " style={{ fontFamily: "Rubik-Light",color: textColor }}>
            Social Media
          </h1>
          <div className='grid grid-cols-3 gap-3 mt-3'>
            {form?.contacts.map((item, index)  => {
              return (
                <div key={index} className='w-[60px] p-3 rounded-md flex justify-center items-center' >
                    <img
                    style={{
                    hieght:'60px', 
                    width: '60px', 
                      borderRadius: "8px",
                    }}
                    src={`${item?.logo}`}
                  />
                </div>
              );
            })}
            {form?.contactsCustom.map((item, index)  => {
                return (
                  <div key={index} className='w-14 p-3 rounded-md flex justify-center items-center' >
                      <img
                      style={{
                      hieght:'60px', 
                      width: '60px', 
                        borderRadius: "8px",
                      }}
                      src={`${item?.logo}`}
                    />
                  </div>
                );
              })}
          </div>
    

          <div className="flex flex-col gap-3 mt-12" style={{backgroundColor:mainBackgroundColor}}>
            {form?.sections.map((item, index) => {
              const url = item.media;
              const isPDF = url?.endsWith(".pdf");
          
              return (
                <div key={index} className="flex flex-col gap-2 justify-between  w-full">
                  {(isPDF || (item && item.content && item.content.length > 0 && (item.content[0]?.type === 'pdf'))) ? (
                      <div className="flex flex-col items-center justify-center mb-14">
                        <h2
                          className="text-2xl md:text-3xl  p-4"
                          style={{ color: textColor }}
                        >
                          {item.sectionName}
                        </h2>
                        <div className='w-[250px]  rounded-2xl border-4  ' style={{backgroundColor:form?.colors.buttonsBackground,borderColor:textColor,borderRadius: "20px"}}>
                          <a href={url} target='_blank' style={{ borderRadius: "40px"}} >
                              <div className='flex justify-center  w-full   rounded-2xl shadow-lg  relative'  >
                              <div className='flex flex-row gap-5'>
                            <BsFiletypePdf
                                            size={90}
                                            className="w-full mt-5 mb-5 "
                                            color={textButtonColor}
                                          />
                        
                              </div>
                              <div className='absolute top-3 right-3'>
                              <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                              <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                              <path fill={textButtonColor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                              </svg>
                              </div>
                              </div>
                          </a>
                        </div>
                      </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex justify-center my-3">
                        <h2 className="text-2xl md:text-3xl " style={{ color: textColor }}>
                          {item.sectionName}
                        </h2>
                      </div>
                      <div className="flex justify-center">
                        <video
                          className={`rounded-3xl border-4 min-w-[250px] h-[150px] aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                          style={{
                            borderColor: `${textColor}`,
                            background: `${form?.colors.buttonsBackground}`,
                          }}
                          controls

                              
                                
                              muted  // This will mute the video, enabling it to   in more browsers
                              preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                             
                        >
                          <source src={url} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

        </div>
   
    
      </div>
    </div>
  );
}

export default Template12;
