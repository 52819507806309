import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Grid, Paper } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useFetchStatsQuery } from "../Redux/API/statistics";

const StatisticsPage = () => {
  const { data, isLoading } = useFetchStatsQuery(1);
  const [dataa, setDataa] = useState([]);
  const token = localStorage.getItem('token');
  const profile = localStorage.getItem('profile');
  const { t } = useTranslation();

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.x-tag.tech/api/profile/${profile}`, config);
        setDataa(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const totalViews = data?.reduce((sum, item) => sum + item.view, 0) || 0;

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '1rem', minHeight: '100vh', backgroundColor: '#fff' }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
            <Typography variant="h4" sx={{ textAlign: 'center', color: '#262626', fontWeight: 'bold' }}>
              {t('totalـcardـTabs')}
            </Typography>
            <Typography variant="h4" sx={{ marginTop: '0.5rem', textAlign: 'center', fontWeight: 'bold', color: 'goldenrod' }}>
              {dataa?.views || 0}
            </Typography>
            <Typography variant="h6" sx={{ marginTop: '1rem', textAlign: 'center', color: '#262626', fontWeight: 'bold' }}>
              {t('totalclickes')}
            </Typography>
            <Typography variant="h4" sx={{ marginTop: '0.5rem', textAlign: 'center', fontWeight: 'bold', color: 'goldenrod' }}>
              {totalViews}
            </Typography>
            <Typography variant="h6" sx={{ marginTop: '2rem', textAlign: 'center', color: '#262626', fontWeight: 'bold' }}>
               {t('LinksTabs')}
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '1rem' }}>
              {data?.map((item, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#000', padding: '1rem', borderRadius: '10%' }}>
                    <img src={item.logo} alt={item.name} style={{ width: '60px', height: '60px', borderRadius: '30%' }} />
                    <Typography sx={{ fontSize: '0.75rem', marginTop: '0.3rem', color: 'goldenrod' }}>
                      {item?.view}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticsPage;
