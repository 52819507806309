import React, { useEffect, useState } from "react";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LanguageIcon from "@mui/icons-material/Language"; // Language icon for toggle button
import { Button, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import {
  setColors,
  setTheme,
  setProfileImage,
  setBasicInfo,
  setContacts,
  setCustomContacts,
  setCoverImage,
  setSections,
  resetState,
  setSelectedLanguage
} from "../Redux/Slices/FormSlice";
import { useDispatch, useSelector } from "react-redux";
 
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../hook/useLocalStorage";
import i18n from "../i18n"; 
import Logo from "../assets/images/Logo.png"; 

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const form = useSelector((state) => state.form);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [languagee, setLanguagee] = useState(localStorage.getItem("lang"));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();

  const resetFormState = () => {
    dispatch(resetState());
  };

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    resetFormState();
    
    localStorage.clear();
  
    if (lang =='en' || lang =='ar'  ) {
      localStorage.setItem("lang", lang);
      setLanguagee(lang)
      setLanguage(lang)
      dispatch(setSelectedLanguage({ SelectedLanguage: lang }));

      console.log('if (l log')
    }
    else  if (lang !=='en' && lang !=='ar'  ) {
      localStorage.setItem("lang", 'en');
      setLanguagee('en')
      setLanguage('en')
      dispatch(setSelectedLanguage({ SelectedLanguage: 'en' }));

      console.log(' else{ log') 
    }
  }, []);
  useEffect(() => {
    i18n.changeLanguage(languagee);
  }, [languagee]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogin = () => {
    setIsLoading(true);
    axios
      .post("https://api.x-tag.tech/api/login", {
        username: email,
        password: password,
      })
      .then((response) => {
        console.log(response, 'userResponse')
  
        const token = response.data.token;
        const profile = response.data.user.profile;
        localStorage.setItem("token", token);
        localStorage.setItem("profile", profile);
  
        if (profile !== null) {
          return axios.get(`https://api.x-tag.tech/api/profile/${profile}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((userResponse) => {
            const formData = userResponse.data.data;
            // const formDataLa = userResponse.data;

  console.log('formDataformDataformData',formData?.SelectedLanguage)
            // Dispatch language,color, theme, and profile info
            dispatch(
              setColors({
                buttonsBackground: formData?.buttonColor,
                mainBackground: formData?.bgColor,
              })
            );
console.log(form,'formformformform')
            if(formData?.SelectedLanguage=='ar' || formData?.SelectedLanguage=='en'  ){
              localStorage.setItem("lang",formData?.SelectedLanguage)

            }
            dispatch(setTheme(formData?.theme));
            dispatch(setProfileImage({ image: formData?.photo }));
            dispatch(setCoverImage({ coverImage: formData?.cover }));
            dispatch(
              setBasicInfo({
                basic: {
                  firstName: formData?.firstName,
                  lastName: formData?.lastName,
                  jobTitle: formData?.jobTitle,
                  businessName: formData?.businessName,
                  location: formData?.location,
                  bio: formData?.bio,
                  phoneNumber: formData?.phoneNum,
                  email: formData?.email,
                  phoneNumSecondary: formData?.phoneNumSecondary,
                },
              })
            );
  
            // Dispatch primary links
            formData?.primary_links?.forEach((contact) => {
              dispatch(
                setContacts({
                  type: contact.name,
                  value: contact.value,
                  idInthback: contact.id,
                  logo: contact.logo,
                })
              );
            });
  
            // Dispatch secondary links
            formData?.second_links?.forEach((customContact, index) => {
              dispatch(
                setCustomContacts({
                  id: index + 1, // Use index + 1 instead of customContact.id
                  link: customContact.link,
                  logo: customContact.logo,
                  name_link: customContact.name_link,
                })
              );
            });
  
            // Dispatch sections
            const sectionsToAdd = formData?.section?.map((section) => ({
              id: section.id,
              title: section.title,
              media: section.media,
              contentType: "video",
            }));
  
            if (sectionsToAdd) {
              dispatch(setSections(sectionsToAdd));
            }
  
            setIsLoading(false);
            navigate("/main");
          });
        } else {
          dispatch(resetState());
          navigate("/main");
          setIsLoading(false);
          return null;
        }
      })
      .catch((err) => {
        console.error("Login failed:", err);
        setError("Login failed. Please check your credentials.");
        setIsLoading(false);
      });
  };
  const handleLanguageToggle = () => {
    const newLang = languagee === "en" ? "ar" : "en";
    setLanguagee(newLang);
    setLanguage(newLang)
    dispatch(setSelectedLanguage({ SelectedLanguage: newLang }));

    localStorage.setItem("lang", newLang);
  };

  return (
    <div
      className="login-page"
      style={{ direction: language === "ar" ? "rtl" : "ltr" }}
    >
      <div className="navbar">
        <img src={Logo} alt="Logo" className="navbar-logo" />
        <IconButton onClick={handleLanguageToggle} className="language-toggle">
          <LanguageIcon />
          <span style={{fontSize:"16px"}}>{languagee === "en" ? "English" : "العربية"}</span>
        </IconButton>
      </div>
      <div className="login-container ">
        <div className="login-form-container">
          <div className="login-form">
            <h1 className="login-title">{languagee === "en" ? "Login" : "تسجيل الدخول"}</h1>
            <form className="login-fields">
              <div className="input-field">
                <label htmlFor="email" className="input-label">
                  {languagee === "en" ? "User Name*" : "اسم المستخدم*"}
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="email"
                  id="email"
                  className="input"
                  placeholder={languagee === "en" ? "ex:xtag3" : "مثال:xtag3"}
                  required
                  style={{ textAlign: languagee === "ar" ? "right" : "left" }}
                />
              </div>
              <div className="input-field">
                <label htmlFor="password" className="input-label">
                  {languagee === "en" ? "Password*" : "كلمة المرور*"}
                </label>
                <div className="input-with-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={languagee === "en" ? "••••••••" : "••••••••"}
                    className="input"
                    required
                    style={{ textAlign: languagee === "ar" ? "right" : "left" }}
                  />
                  <button
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                    className="password-toggle"
                    style={{
                      [languagee === "ar" ? "left" : "right"]: "10px", // Switch icon position
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </button>
                </div>
              </div>
              {error && <Typography color="error">{error}</Typography>}
              <Button
                onClick={handleLogin}
                fullWidth
                variant="contained"
                className="login-button"
                disabled={isLoading || email === "" || password === ""}
              >
                {isLoading ? (
                  <CircularProgress size={24} className="loading-spinner" />
                ) : (
                  <h4 className="login-button-text">
                    {languagee === "en" ? "Login" : "تسجيل الدخول"}
                  </h4>
                )}
              </Button>
              <div className="login-footer">
                <p className="terms">
                  {languagee === "en"
                    ? "By proceeding, you agree to terms & conditions."
                    : "بالمتابعة، أنت توافق على الشروط والأحكام."}
                </p>
              </div>
            </form>
          </div>
        </div>
        {!isSmallScreen && (
          <div className="login-image-card">
            
              <div className="card-front">
                <img
                  src={Logo}
                  alt="Login English"
                  className="login-image"
                />
              </div>
             
           
          </div>
        )}
      </div>
      <style jsx>{`
        .login-page {
          position: relative;
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #262626;
        }
      
        .navbar {
          position: fixed;
          top: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #262626;
          padding: 0 20px;
          animation: backgroundMove 8s linear infinite;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          z-index: 10;
        }
      
        .language-toggle {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: #ffffff;
        }
      
        .navbar-logo {
          width: 65px;
          hight:"auto";
        }
      
        @keyframes backgroundMove {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 100% 50%;
          }
        }
      
        @keyframes scaleAnimation {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(1.1);
          }
        }
      
        .login-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2rem;
          max-width: 850px;
          width: 100%; /* Ensuring consistent width */
          padding: 2.5rem;
          border-radius: 20px;
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
         
          backdrop-filter: blur(20px);
          margin-top: 150px;
          margin-bottom: 100px;
        }
      
        .login-form-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      
        .login-form {
          background: rgba(38, 38, 38, 0.9);
          padding: 2.5rem;
          border-radius: 20px;
          width: 100%;
          max-width: 400px;
          box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
        }
      
        .login-title {
          font-size: 2.4rem;
          text-align: center;
          margin-bottom: 1.5rem;
          color: #fff;
        }
      
        .login-fields {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      
        .input-field {
          display: flex;
          flex-direction: column;
          position: relative;
        }
      
        .input-label {
          margin-bottom: 0.5rem;
          font-size: 0.8rem;
          color: #fff;
          font-weight: bold;
        }
      
        .input {
          padding: 0.8rem;
          border: 1px solid #dfe6ed;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.7);
          color: #333;
          outline: none;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease;
          width: 100%;
        }
      
        .input:focus {
          box-shadow: 0 0 8px rgba(23, 72, 138, 0.5);
        }
      
        .input-with-icon {
          position: relative;
          display: flex;
          align-items: center;
        }
      
        .password-toggle {
          position: absolute;
          background: none;
          border: none;
          cursor: pointer;
          color: #fff;
          padding: 0;
          margin: 0;
        }
      
        .login-button {
          background: #D9A31F;
          border: none;
          padding: 0.8rem 1.5rem;
          border-radius: 8px;
          font-size: 1rem;
          cursor: pointer;
          color: white;
         
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.5rem;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
       
          animation: gradientAnimation 4s ease infinite;
        }
      
      
        .loading-spinner {
          color: white;
        }
      
        .login-button-text {
          font-weight: bold;
        }
      
        .login-footer {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 1.5rem;
          color: #497cbe;
        }
      
        .terms {
          font-size: 0.8rem;
          color: #aaa;
        }
      
        .login-image-card {
          perspective: 1000px;
          width: 50%;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      
        .card-inner {
          position: relative;
          width: 100%;
          height: 300px;
          text-align: center;
          transform-style: preserve-3d;
          transition: transform 0.8s;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
          border-radius:30px;
        }
    
      
      
      
        .card-front {
       
          display: flex;
          justify-content: center;
          align-items: center;
        }
      
        
        .login-image {
          max-width: 100%;
          max-height: 100%;
          border-radius: 15px;
        }
      
        @keyframes float {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(-100px);
          }
          100% {
            transform: translateY(0px);
          }
        } 
      
        @keyframes slightRotate {
          0% {
            transform: rotateX(0deg) rotateY(0deg);
          }
          40% {
            transform: rotateX(20deg) rotateY(20deg);
          }
           
          
          100% {
            transform: rotateX(0deg) rotateY(0deg);
          }
        }
      
        @media (min-width: 768px) {
          .login-container {
            flex-direction: row;
          }
      
          .login-image-container {
            width: 50%;
          }
      
          .login-form-container {
            width: 50%;
          }
        }
      `}</style>
      
      
    </div>
  );
};

export default Login;
